import Vue from 'vue'
import Vuex from 'vuex'
import billStore from './modules/bill';
import inOutReport from './modules/inoutreport.js';
import customerSupport from './modules/customerSupport';
import arLang from '@/store/arLang.js'
import enLang from '@/store/enLang.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logo: 'ecar-logo.png',
    daysList: [
      {text: '1', value: '1'},
      {text: '2', value: '2'},
      {text: '3', value: '3'},
      {text: '4', value: '4'},
      {text: '5', value: '5'},
      {text: '6', value: '6'},
      {text: '7', value: '7'},
      {text: '8', value: '8'},
      {text: '9', value: '9'},
      {text: '10', value: '10'},
      {text: '12', value: '12'},
      {text: '13', value: '13'},
      {text: '14', value: '14'},
      {text: '15', value: '15'},
      {text: '16', value: '16'},
      {text: '17', value: '17'},
      {text: '18', value: '18'},
      {text: '19', value: '19'},
      {text: '20', value: '20'},
      {text: '21', value: '21'},
      {text: '22', value: '22'},
      {text: '23', value: '23'},
      {text: '24', value: '24'},
      {text: '25', value: '25'},
      {text: '26', value: '26'},
      {text: '27', value: '27'},
      {text: '28', value: '28'},
      {text: '29', value: '29'},
      {text: '30', value: '30'},
      {text: '31', value: '31'},
    ],
    monthsList: [
      {text: '1', value: '1'},
      {text: '2', value: '2'},
      {text: '3', value: '3'},
      {text: '4', value: '4'},
      {text: '5', value: '5'},
      {text: '6', value: '6'},
      {text: '7', value: '7'},
      {text: '8', value: '8'},
      {text: '9', value: '9'},
      {text: '10', value: '10'},
      {text: '12', value: '12'}
    ],
    yearList: [
      {text: '2021', value: '2021'},
      {text: '2022', value: '2022'},
      {text: '2023', value: '2023'},
      {text: '2024', value: '2024'},
      {text: '2025', value: '2025'},
      {text: '2026', value: '2026'},
    ],
    plateChars: [
        {text: '', value: ''},
        {text: 'أ - A', value: 'ا'},
        {text: 'ب - B', value: 'ب'},
        {text: 'ح - J', value: 'ح'},
        {text: 'د - D', value: 'د'},
        {text: 'ر - R', value: 'ر'},
        {text: 'س - S', value: 'س'},
        {text: 'ص - X', value: 'ص'},
        {text: 'ط - T', value: 'ط'},
        {text: 'ع - E', value: 'ع'},
        {text: 'ق - G', value: 'ق'},
        {text: 'ك - K', value: 'ك'},
        {text: 'ل - L', value: 'ل'},
        {text: 'م - Z', value: 'م'},
        {text: 'ن - N', value: 'ن'},
        {text: 'ه - H', value: 'ه'},
        {text: 'و - U', value: 'و'},
        {text: 'ى - V', value: 'ى'},
    ],
    showLoading: false,
    showFirst: true,
    showMessage: true,
    customers: [],
    COOKIEPhase: 'SAMCCCC-Cookies',
    LangCooki: 'SAMCLANG-Cookies',
    SAMCOTEC: {
      r_path: '/api/index.php', 
      p_path: '/api/export-print.php',
      e_path: '../api/excel.php', 
      pd_path: '../api/pdf.php',
    },
    settings: {
      dev_vat: 15,
    },
    currentMenu: "CurrentMenu",
    vcurrentMenu: "VCurrentMenu",
    currentlink: "currentlink",
    userinfo: [],
    lang:{
      en: enLang,
      ar: arLang,     
    },
    activemenu: 'bill',
    loginRedirect: '',
    menu: {
      nsmall:{
        dashboard: {
          main: {
            index: 1,
            arname: 'الإدارة',
            enname: 'Managment',
            urduname: '',
            href: '/ecar',
            to: '/ecar',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 1,
              arname: 'الإدارة',
              enname: 'Management',
              href:'/dashboard',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crmx']
            },
          ]
        },
        customers: {
          main: {
            index: 2,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:2,
              main: 2,
              arname: 'التقويم',
              enname: 'Calendar',
              href:'/CRMCalendar',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crmx']
            },
            {
              index:10,
              main: 2,
              arname: 'المشاريع',
              enname: 'Project',
              href:'/Project',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:8,
              main: 2,
              arname: 'قائمة العملاء',
              enname: 'Dealing',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            // {
            //   index:1,
            //   main: 1,
            //   arname: 'تحت التفاوض',
            //   enname: 'Dealing',
            //   href:'/newCRM',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['crm']
            // },
            {
              index:7,
              main: 2,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/hCalendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:7,
              main: 2,
              arname: 'العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:7,
              main: 2,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['cars']
            },
            {
              index:7,
              main: 2,
              arname: 'المهام',
              enname: 'tasks',
              href:'/tasks',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['carssss']
            },
            
            // {
            //   index:8,
            //   main: 1,
            //   arname: 'مراجعة العملاء',
            //   enname: 'Client Review',
            //   href:'/billClients',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['crm']
            // },
            {
              index:9,
              main: 2,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm']
            },
            
            {
              index:7,
              main: 2,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['carsss']
            },
          ]
        },
        sales: {
          main: {
            index: 3,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            // {
            //   index:7,
            //   main: 1,
            //   arname: 'العملاء',
            //   enname: 'Customers',
            //   href:'/clientList',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            {
              index:8,
              main: 3,
              arname: 'العملاء الحاليين',
              enname: 'Client Review',
              href:'/bill_clients', 
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm']
            },
            {
              index:10,
              main: 3,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:10,
              main: 3,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:1,
              main: 3,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:1,
              main: 3,
              arname: 'نقطة البيع',
              enname: 'POS',
              href:'/POS-RETAIL',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['POSREST']
            },
            {
              index:2,
              main: 3,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['cars']
            },
            // {
            //   index:7,
            //   main: 2,
            //   arname: 'عرض السعر',
            //   enname: 'Quotations',
            //   href:'/quotations/n/1',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            {
              index:8,
              main: 3,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
          ]
        },
        accounting: {
          main: {
            index:4,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [

            {
              index:4,
              main: 4,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:5,
              main: 4,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            // {
            //   index:8,
            //   main: 2,
            //   arname: 'طباعة سند قبض',
            //   enname: 'ٌReceipt Voucher',
            //   href:'/finance/inreceipt',
            //   class:'',
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:9,
              main: 4,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['anyss']
            },
            // {
            //   index:2,
            //   main: 3,
            //   arname: 'تقرير النسب',
            //   enname: 'Precentage Report',
            //   href:'/reports/percent-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: `'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:3,
            //   main: 4,
            //   arname: 'تقرير التأمينات',
            //   enname: 'GOZI Report',
            //   href:'/emps/gozi-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: ' اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:1,
            //   main: 2,
            //   arname: 'تقرير اليومية',
            //   enname: 'Income Report',
            //   href:'/reports/income-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:3,
            //   main: 2,
            //   arname: 'تقرير المبيعات',
            //   enname: 'Sales Report',
            //   href:'/reports/sales-reports',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:4,
            //   main: 2,
            //   arname: 'تقرير المرتجع',
            //   enname: 'Return Report',
            //   href:'/reports/return-reports',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carss']
            },
            { 
              index:5,
              main: 6,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report-contract',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            // {
            //   index:6,
            //   main: 2,
            //   arname: 'كشف حساب',
            //   enname: 'Statement',
            //   href:'/reports/statment-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:7,
            //   main: 4,
            //   arname: 'تقرير النسب',
            //   enname: 'Percent Report',
            //   href:'/reports/percent-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:7,
            //   main: 4,
            //   arname: 'تقرير الرواتب',
            //   enname: 'Salary Report',
            //   href:'/reports/salary-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'اعدادات الواتس',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            // {
            //   index:4,
            //   main: 3,
            //   arname: 'الموظفين',
            //   enname: 'Employees',
            //   href:'/settings/employees',
            //   class:'mytab4',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   style: '',
            //   perms: ['onlySalesPurchase']
            // },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      smallif:{
        customers: {
          main: {
            index: 1,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 1,
              arname: 'التقويم',
              enname: 'Calendar',
              href:'/CRMCalendar',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['crmx']
            },
            {
              index:10,
              main: 1,
              arname: 'المشاريع',
              enname: 'Project',
              href:'/Project',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:8,
              main: 1,
              arname: 'العملاء',
              enname: 'Customers',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[3],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تحت التفاوض',
              enname: 'Dealing',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 1,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[3],
              perms: ['any']
            },
            {
              index:9,
              main: 1,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[3],
              perms: ['any']
            },
            // {
            //   index:1,
            //   main: 1,
            //   arname: 'تحت التفاوض',
            //   enname: 'Dealing',
            //   href:'/newCRM',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['crm']
            // },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/hCalendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:7,
              main: 1,
              arname: 'العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['cars']
            },
            {
              index:7,
              main: 1,
              arname: 'المهام',
              enname: 'tasks',
              href:'/tasks',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['cars']
            },
            
            // {
            //   index:8,
            //   main: 1,
            //   arname: 'مراجعة العملاء',
            //   enname: 'Client Review',
            //   href:'/billClients',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['crm']
            // },
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm']
            },
            
            {
              index:7,
              main: 1,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,4,5,6,7,8],
              perms: ['cars']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            // {
            //   index:7,
            //   main: 1,
            //   arname: 'العملاء',
            //   enname: 'Customers',
            //   href:'/clientList',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            {
              index:8,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Client Review',
              href:'/bill_clients', 
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm']
            },
            {
              index:10,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:10,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['crm']
            },
            {
              index:1,
              main: 2,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:1,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              href:'/POS-RETAIL',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['POSREST']
            },
            {
              index:2,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['cars']
            },
            // {
            //   index:7,
            //   main: 2,
            //   arname: 'عرض السعر',
            //   enname: 'Quotations',
            //   href:'/quotations/n/1',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            
          ]
        },
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [

            {
              index:4,
              main: 3,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 3,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:5,
              main: 3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            // {
            //   index:8,
            //   main: 2,
            //   arname: 'طباعة سند قبض',
            //   enname: 'ٌReceipt Voucher',
            //   href:'/finance/inreceipt',
            //   class:'',
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 4,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 4,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            // {
            //   index:2,
            //   main: 3,
            //   arname: 'تقرير النسب',
            //   enname: 'Precentage Report',
            //   href:'/reports/percent-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: `'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:3,
            //   main: 4,
            //   arname: 'تقرير التأمينات',
            //   enname: 'GOZI Report',
            //   href:'/emps/gozi-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
          ]
        },
        reports: {
          main: {
            index: 5,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: ' اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:1,
            //   main: 2,
            //   arname: 'تقرير اليومية',
            //   enname: 'Income Report',
            //   href:'/reports/income-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:2,
              main: 5,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:3,
            //   main: 2,
            //   arname: 'تقرير المبيعات',
            //   enname: 'Sales Report',
            //   href:'/reports/sales-reports',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:4,
            //   main: 2,
            //   arname: 'تقرير المرتجع',
            //   enname: 'Return Report',
            //   href:'/reports/return-reports',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:5,
              main: 5,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carss']
            },
            { 
              index:5,
              main: 5,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report-contract',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            // {
            //   index:6,
            //   main: 2,
            //   arname: 'كشف حساب',
            //   enname: 'Statement',
            //   href:'/reports/statment-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:7,
            //   main: 4,
            //   arname: 'تقرير النسب',
            //   enname: 'Percent Report',
            //   href:'/reports/percent-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            // {
            //   index:7,
            //   main: 4,
            //   arname: 'تقرير الرواتب',
            //   enname: 'Salary Report',
            //   href:'/reports/salary-report',
            //   class:'mytab4',
            //   groupid:[1,2],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '', //smclass: 'font-small',
            //   style: '',
            //   perms: ['any']
            // },
            
          ]
        },
        settings: {
          main: {
            index: 6,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'اعدادات الواتس',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            // {
            //   index:4,
            //   main: 3,
            //   arname: 'الموظفين',
            //   enname: 'Employees',
            //   href:'/settings/employees',
            //   class:'mytab4',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   style: '',
            //   perms: ['onlySalesPurchase']
            // },
            
            {
              index:6,
              main: 6,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      erp1:{
        customers: {
          main: {
            index: 1,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:2,
              main: 1,
              arname: 'المشاريع',
              enname: 'Projects',
              href:'/CRMCalendar',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm']
            },
            {
              index:4,
              main: 1,
              arname: 'العملاء المحتملين',
              enname: 'Leads',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 1,
              arname: 'العملاء',
              enname: 'Customers',
              href:'/projects',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 1,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:6,
              main: 1,
              arname: 'التقارير',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
          ]
        },
        accounting: {
          main: {
            index: 2,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 2,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 2,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 2,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 2,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 2,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 2,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 3,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any33']
            },
            {
              index:5,
              main: 3,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 4,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 4,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 4,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        reports: {
          main: {
            index: 5,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: ' اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 5,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 5,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 5,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 5,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 5,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:9,
              main: 5,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 5,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars2']
            },
            {
              index:11,
              main: 5,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report-contract',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carsd']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 5,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 5,
              arname: 'الاقل مبيعا',
              enname: 'Most Sales',
              href:'/report/ititems-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        settings: {
          main: {
            index: 6,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            
            {
              index:5,
              main: 6,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      fatora:{
        customers: {
          main: {
            index: 1,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList-mobile',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[9],
              perms: ['any']
            },
            {
              index:7,
              main: 1,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            
          ]
        },
        purchase: {
          main: {
            index: 3,
            arname: 'المشتريات',
            enname: 'Purchase',
            href: '/purchase',
            to: '/purchase',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:1,
              main: 3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            
          ]
        },
        reports: {
          main: {
            index: 4,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/income-report',
            to: '/income-report',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 4,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/income-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:1,
              main: 4,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:6,
              main: 4,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['showExpenseReport']
            },
          ]
        },
        settings: {
          main: {
            index: 6,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            // {
            //   index:3,
            //   main: 6,
            //   arname: 'الصلاحيات',
            //   enname: 'Permissions',
            //   href:'/settings/permissions-settings',
            //   style: '',
            //   class:'mytab4',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1],
            //   perms: ['any']
            // },
            // {
            //   index:3,
            //   main: 6,
            //   arname: 'اعدادات الواتس',
            //   enname: 'WhatsApp Settings',
            //   href:'/settings-whatsapp',
            //   style: '',
            //   class:'mytab4',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1],
            //   perms: ['any']
            // },
            {
              index:5,
              main: 6,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:6,
              main: 6,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      erp:{
        customers: {
          main: {
            index: 1,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/sales',
            to: '/sales',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:10,
              main: 1,
              arname: 'تحت التفاوض',
              enname: 'Dealing',
              href:'/sales',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['sales_crm']
            },
            {
              index:9,
              main: 1,
              arname: 'الحملات الاعلانية',
              enname: 'Marketing',
              href:'/whatsSet',
              class:'mytab4',
              groupid:[1,2,3,4,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['sales_crm']
            },
            // {
            //   index:9,
            //   main: 9,
            //   arname: 'اعدادات الواتس',
            //   enname: 'WhatsApp Settings',
            //   href:'/settings',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['isBill']
            // },
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['isBill']
            },
            // {
            //   index:1,
            //   main: 9,
            //   arname: 'التقويم',
            //   enname: 'Calendar',
            //   href:'/CRMCalendar',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['anysss']
            // },
            {
              index:8,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList-mobile',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[9],
              perms: ['any']
            },
            
            {
              index:7,
              main: 1,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            // {
            //   index:2,
            //   main: 1,
            //   arname: 'عقود الصيانة',
            //   enname: 'Maint. Contracts',
            //   href:'/maintContracts',
            //   style: '', 
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['car_contract']
            // },
            {
              index:2,
              main: 1,
              arname: 'أمر تشغيل',
              enname: 'Job Card',
              href:'/cars-cards',
              style: '', 
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            // {
            //   index:1,
            //   main: 9,
            //   arname: 'تحت التفاوض',
            //   enname: 'Dealing',
            //   href:'/newCRM',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['crm']
            // },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/hCalendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm1']
            },
            {
              index:7,
              main: 1,
              arname: 'العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm1']
            },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars22']
            },
            // {
            //   index:7,
            //   main: 9,
            //   arname: 'المهام',
            //   enname: 'tasks',
            //   href:'/tasks',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['cars']
            // },
            
            // {
            //   index:8,
            //   main: 1,
            //   arname: 'مراجعة العملاء',
            //   enname: 'Client Review',
            //   href:'/billClients',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['crm']
            // },
            
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm4']
            },
            
            {
              index:7,
              main: 1,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars4']
            },
            // {
            //   index:9,
            //   main: 9,
            //   arname: 'العروض',
            //   enname: 'Offers',
            //   href:'/offers',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:9,
              main: 1,
              arname: 'تقارير الواتس',
              enname: 'WhatsApp Report',
              href:'/whatsReport',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['isBillx']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Current Clients',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['isBill']
            },
            {
              index:4,
              main: 2,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:3,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm3']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/whats-support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBill']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBillx']
            },
            {
              index:2,
              main: 2,
              arname: 'عقود الصيانة',
              enname: 'Maint. Contracts',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            
            
            {
              index:5,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              href:'/POS-RETAIL',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['POSREST']
            },
            {
              index:6,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['anys']
            },
            {
              index:7,
              main: 2,
              arname: 'تقرير الارباح',
              enname: 'card Profits',
              href:'/card-profit-reports',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            
          ]
        },
        
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any33']
            },
            {
              index:4,
              main: 4,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: ' اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 6,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/pos/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 6,
              arname: 'الاقل مبيعا',
              enname: 'Most Sales',
              href:'/pos/report/ititems-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'اعدادات الواتس',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:5,
              main: 7,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        logout: {
          main: {
            index: 55,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8,9],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      erpsam3:{
        customers: {
          main: {
            index: 1,
            arname: 'العملاء',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 1,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:10,
              main: 1,
              arname: 'تحت التفاوض',
              enname: 'Dealing',
              // href:'/sales',
              href:'/sales',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['sales_crm1']
            },
            {
              index:10,
              main: 1,
              arname: 'الصيانة الدورية',
              enname: 'Recuring Maint',
              href:'/periodic-maint',
              // href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showPeriodMaint']
            },
            {
              index:7,
              main: 1,
              arname: 'برامج صيانة',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['showOffering']
            },
            {
              index:7,
              main: 1,
              arname: 'عروض سنوية',
              enname: 'Subscriber',
              href:'/subscriber',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showSubscriber']
            },
            {
              index:10,
              main: 1,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 1,
              arname: 'صيانة خارجية',
              enname: 'External Maint.',
              href:'/external-maint',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showExMaint1']
            },
            {
              index:10,
              main: 1,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:9,
              main: 1,
              arname: 'الحملات الاعلانية',
              enname: 'Marketing',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showWhatsMarket']
            },
            
            // {
            //   index:9,
            //   main: 9,
            //   arname: 'اعدادات الواتس',
            //   enname: 'WhatsApp Settings',
            //   href:'/settings',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['isBill']
            // },
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['isBill']
            },
            {
              index:9,
              main: 1,
              arname: 'الكول سنتر',
              enname: 'Call Center',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            
            // {
            //   index:1,
            //   main: 9,
            //   arname: 'التقويم',
            //   enname: 'Calendar',
            //   href:'/CRMCalendar',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['anysss']
            // },
            
            {
              index:8,
              main: 1,
              arname: 'قائمة العملاء',
              enname: 'Clients',
              href:'/clientList-mobile',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[9],
              perms: ['any']
            },
            
            
            // {
            //   index:2,
            //   main: 1,
            //   arname: 'عقود الصيانة',
            //   enname: 'Maint. Contracts',
            //   href:'/maintContracts',
            //   style: '', 
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['car_contract']
            // },
            {
              index:2,
              main: 1,
              arname: 'أمر تشغيل',
              enname: 'Job Card',
              href:'/cars-cards',
              style: '', 
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            // {
            //   index:1,
            //   main: 9,
            //   arname: 'تحت التفاوض',
            //   enname: 'Dealing',
            //   href:'/newCRM',
            //   class:'',
            //   // style: 'background:green !important;color:#fff !important;',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['crm']
            // },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/hCalendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm1']
            },
            {
              index:7,
              main: 1,
              arname: 'العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['crm1']
            },
            {
              index:7,
              main: 1,
              arname: 'المواعيد',
              enname: 'calendar',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars22']
            },
            // {
            //   index:7,
            //   main: 9,
            //   arname: 'المهام',
            //   enname: 'tasks',
            //   href:'/tasks',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['cars']
            // },
            
            // {
            //   index:8,
            //   main: 1,
            //   arname: 'مراجعة العملاء',
            //   enname: 'Client Review',
            //   href:'/billClients',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['crm']
            // },
            
            {
              index:9,
              main: 1,
              arname: 'ارسال واتس',
              enname: 'Send WhatsApp',
              href:'/WhatsApp',
              class:'mytab4',
              groupid:[1,2,3,4,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['crm4']
            },
            
            {
              index:7,
              main: 1,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars4']
            },
            // {
            //   index:9,
            //   main: 9,
            //   arname: 'العروض',
            //   enname: 'Offers',
            //   href:'/offers',
            //   class:'mytab4',
            //   groupid:[1,2,3,4,6,7,8],
            //   // smclass: '', //smclass: 'font-small',
            //   smclass: '',
            //   style: '',
            //   perms: ['any']
            // },
            {
              index:9,
              main: 1,
              arname: 'تقارير الواتس',
              enname: 'WhatsApp Report',
              href:'/whatsReport',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['isBillx']
            },
            {
              index:9,
              main: 1,
              arname: 'الكول سنتر',
              enname: 'Call Center',
              href:'/call-center',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Current Clients',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['isBill']
            },
            {
              index:4,
              main: 2,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:3,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm3']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/whats-support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBill']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBillx']
            },
            {
              index:2,
              main: 2,
              arname: 'صيانة دورية',
              enname: 'Maint. Contracts',
              href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            {
              index:5,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              href:'/POS-RETAIL',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['POSREST']
            },
            {
              index:6,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['anys']
            },
            {
              index:2,
              main: 2,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            
            {
              index:7,
              main: 2,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 2,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
            
          ]
        },
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/stores',
            to: '/stores',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 4,
              arname: 'الاقل مبيعا',
              enname: 'Less Sales',
              href:'/report/items-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        purchase: {
          main: {
            index: 12,
            arname: 'المشتريات',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 12,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'أمر شراء',
              style: '',
              enname: 'Purchase',
              href:'/purchase-order',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'مرتجع المشتريات',
              style: '',
              enname: 'purchase Return',
              href:'/purchaseReturn',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },

            {
              index:4,
              main: 12,
              arname: 'تقرير المشتريات',
              style: '',
              enname: 'Purchase Report',
              href:'/purchase-report',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير النسب',
              enname: 'Percentage Report',
              href:'/reports/percent-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الاجازات',
              enname: 'Vacation Report',
              href:'/reports/vacation_report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'تقرير الدليل',
              enname: 'COA Report',
              href:'/coa-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الدليل بالمجاميع',
              enname: 'COA Totals Report',
              href:'/coa-reports-totals',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'اعدادات الواتس',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:5,
              main: 7,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 55,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      erpsam:{ 
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            href: '/dashboard',
            to: '/dashboard',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              href:'/dashboard',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:7,
              main: 88,
              arname: 'اعدادات العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['showOffering']
            },
            {
              index:9,
              main: 88,
              arname: 'مركز الاتصال',
              enname: 'Call Center',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:9,
              main: 88,
              arname: 'التواصل الاجتماعي',
              enname: 'Social Media',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            // {
            //   index:10,
            //   main: 88,
            //   arname: 'تقرير الضمان',
            //   enname: 'Recuring Maint',
            //   href:'/periodic-maint',
            //   // href:'',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['any']
            // },
            {
              index:10,
              main: 88,
              arname: 'الصيانة الدورية',
              enname: 'Recuring Maint',
              href:'/periodic-maint',
              // href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showPeriodMaint']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:7,
              main: 88,
              arname: 'العروض التسويقية',
              enname: 'Subscriber',
              href:'/subscriber',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showSubscriber']
            },
            
            
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود التأمين',
              enname: 'Insurance. Contract',
              href:'/insurance-contracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
           
            
            {
              index:7,
              main: 88,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars4']
            },
            
            
          ]
        },
        workJob: {
          main: {
            index: 99,
            arname: 'الصيانة',
            enname: 'Works',
            href: '/home',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:6,
              main: 99,
              arname: 'الفحص',
              enname: 'Check Car',
              href:'',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:10,
              main: 99,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:2,
              main: 99,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 99,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 99,
              arname: 'صيانة خارجية',
              enname: 'External Maint.',
              href:'/external-maint',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 99,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2,3,4,5,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الارباح',
              enname: 'card Profits',
              href:'/card-profit-reports',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Current Clients',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['isBill']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'نقاط البيع',
              enname: 'POS',
              href:'/POS',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm3']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/whats-support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBill']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBillx']
            },
            {
              index:2,
              main: 2,
              arname: 'صيانة دورية',
              enname: 'Maint. Contracts',
              href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            {
              index:5,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              // href:'/POS-RETAIL',
              href:'',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['POSREST']
            },
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['anys']
            },
            {
              index:1,
              main: 2,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
          ]
        },
        
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 3,
              arname: 'مراكز التكلفة',
              enname: 'Cost Centers',
              href:'/cost-center',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:5,
            //   main: 3,
            //   arname: 'انواع المصروفات',
            //   enname: 'Expenses Types',
            //   href:'/settings/expensesTypes',
            //   class:'mytab4',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   perms: ['onlySalesPurchase']
            // },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/stores',
            to: '/stores',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 4,
              arname: 'الاقل مبيعا',
              enname: 'Less Sales',
              href:'/report/items-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        purchase: {
          main: {
            index: 12,
            arname: 'المشتريات',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 12,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'أمر شراء',
              style: '',
              enname: 'Purchase',
              href:'/purchase-order',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'مرتجع المشتريات',
              style: '',
              enname: 'purchase Return',
              href:'/purchaseReturn',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },

            {
              index:4,
              main: 12,
              arname: 'تقرير المشتريات',
              style: '',
              enname: 'Purchase Report',
              href:'/purchase-report',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير النسب',
              enname: 'Percentage Report',
              href:'/reports/percent-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الاجازات',
              enname: 'Vacation Report',
              href:'/reports/vacation_report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 5,
              arname: 'جهاز البصمة',
              enname: 'Supervisor Report',
              href:'',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            
            
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'تقرير الدليل',
              enname: 'COA Report',
              href:'/coa-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الدليل بالمجاميع',
              enname: 'COA Totals Report',
              href:'/coa-reports-totals',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      demoerpsam:{ 
        website: {
          main: {
            index: 98,
            arname: 'الموقع والمتجر',
            enname: 'Web & Store',
            urduname: 'فروخت',
            href: '/website',
            to: '/website',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 98,
              arname: 'الموقع الالكتروني',
              enname: 'website',
              href:'/website',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 98,
              arname: 'المتجر',
              enname: 'web-store',
              href:'/web-store',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            
          ]
        },
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            href: '/dashboard',
            to: '/dashboard',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              href:'/dashboard',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:7,
              main: 88,
              arname: 'اعدادات العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['showOffering']
            },
            {
              index:9,
              main: 88,
              arname: 'مركز الاتصال',
              enname: 'Call Center',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:9,
              main: 88,
              arname: 'التواصل الاجتماعي',
              enname: 'Social Media',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            // {
            //   index:10,
            //   main: 88,
            //   arname: 'تقرير الضمان',
            //   enname: 'Recuring Maint',
            //   href:'/periodic-maint',
            //   // href:'',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['any']
            // },
            {
              index:10,
              main: 88,
              arname: 'الصيانة الدورية',
              enname: 'Recuring Maint',
              href:'/periodic-maint',
              // href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showPeriodMaint']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:7,
              main: 88,
              arname: 'العروض التسويقية',
              enname: 'Subscriber',
              href:'/subscriber',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showSubscriber']
            },
            
            
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود التأمين',
              enname: 'Insurance. Contract',
              href:'/insurance-contracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
           
            
            {
              index:7,
              main: 88,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['cars4']
            },
            
            
          ]
        },
        workJob: {
          main: {
            index: 99,
            arname: 'الصيانة',
            enname: 'Works',
            href: '/home',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:6,
              main: 99,
              arname: 'الفحص',
              enname: 'Check Car',
              href:'',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:10,
              main: 99,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:2,
              main: 99,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 99,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 99,
              arname: 'صيانة خارجية',
              enname: 'External Maint.',
              href:'/external-maint',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 99,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2,3,4,5,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الارباح',
              enname: 'card Profits',
              href:'/card-profit-reports',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'العملاء الحاليين',
              enname: 'Current Clients',
              href:'/bill_clients',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['isBill']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'نقاط البيع',
              enname: 'POS',
              href:'/POS',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التجديد',
              enname: 'Renewal',
              href:'/renewal',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['samcrm3']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/whats-support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBill']
            },
            {
              index:2,
              main: 2,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'/support',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['isBillx']
            },
            {
              index:2,
              main: 2,
              arname: 'صيانة دورية',
              enname: 'Maint. Contracts',
              href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['car_contract']
            },
            {
              index:5,
              main: 2,
              arname: 'نقطة البيع',
              enname: 'POS',
              // href:'/POS-RETAIL',
              href:'',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['POSREST']
            },
            {
              index:7,
              main: 2,
              arname: 'عرض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['anys']
            },
            {
              index:1,
              main: 2,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
          ]
        },
        
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 3,
              arname: 'مراكز التكلفة',
              enname: 'Cost Centers',
              href:'/cost-center',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            // {
            //   index:5,
            //   main: 3,
            //   arname: 'انواع المصروفات',
            //   enname: 'Expenses Types',
            //   href:'/settings/expensesTypes',
            //   class:'mytab4',
            //   style: '',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2],
            //   perms: ['onlySalesPurchase']
            // },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/stores',
            to: '/stores',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 4,
              arname: 'الاقل مبيعا',
              enname: 'Less Sales',
              href:'/report/items-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        purchase: {
          main: {
            index: 12,
            arname: 'المشتريات',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 12,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'أمر شراء',
              style: '',
              enname: 'Purchase',
              href:'/purchase-order',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'مرتجع المشتريات',
              style: '',
              enname: 'purchase Return',
              href:'/purchaseReturn',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },

            {
              index:4,
              main: 12,
              arname: 'تقرير المشتريات',
              style: '',
              enname: 'Purchase Report',
              href:'/purchase-report',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير النسب',
              enname: 'Percentage Report',
              href:'/reports/percent-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الاجازات',
              enname: 'Vacation Report',
              href:'/reports/vacation_report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 5,
              arname: 'جهاز البصمة',
              enname: 'Supervisor Report',
              href:'',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            
            
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'تقرير الدليل',
              enname: 'COA Report',
              href:'/coa-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الدليل بالمجاميع',
              enname: 'COA Totals Report',
              href:'/coa-reports-totals',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      samerp:{ 
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            href: '/dashboard',
            to: '/dashboard',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              href:'/dashboard',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:7,
              main: 88,
              arname: 'اعدادات العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['showOffering']
            },
            {
              index:9,
              main: 88,
              arname: 'مركز الاتصال',
              enname: 'Call Center',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:9,
              main: 88,
              arname: 'التواصل الاجتماعي',
              enname: 'Social Media',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            // {
            //   index:10,
            //   main: 88,
            //   arname: 'تقرير الضمان',
            //   enname: 'Recuring Maint',
            //   href:'/periodic-maint',
            //   // href:'',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['any']
            // },
            {
              index:10,
              main: 88,
              arname: 'الصيانة الدورية',
              enname: 'Recuring Maint',
              href:'/periodic-maint',
              // href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showPeriodMaint']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:7,
              main: 88,
              arname: 'العروض التسويقية',
              enname: 'Subscriber',
              href:'/subscriber',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showSubscriber']
            },
            
            
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود التأمين',
              enname: 'Insurance. Contract',
              href:'/insurance-contracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
           
            
            {
              index:7,
              main: 88,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['workreport']
            },
            
            
          ]
        },
        workJob: {
          main: {
            index: 99,
            arname: 'الصيانة',
            enname: 'Works',
            href: '/sam-home',
            to: '/sam-home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:10,
              main: 99,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/sam-home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:2,
              main: 99,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 99,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'نقاط البيع',
              enname: 'POS',
              href:'/POS',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:1,
              main: 2,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
          ]
        },
        
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 3,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/stores',
            to: '/stores',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 4,
              arname: 'الاقل مبيعا',
              enname: 'Less Sales',
              href:'/report/items-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        purchase: {
          main: {
            index: 12,
            arname: 'المشتريات',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 12,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'أمر شراء',
              style: '',
              enname: 'Purchase',
              href:'/purchase-order',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'مرتجع المشتريات',
              style: '',
              enname: 'purchase Return',
              href:'/purchaseReturn',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },

            {
              index:4,
              main: 12,
              arname: 'تقرير المشتريات',
              style: '',
              enname: 'Purchase Report',
              href:'/purchase-report',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير النسب',
              enname: 'Percentage Report',
              href:'/reports/percent-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الاجازات',
              enname: 'Vacation Report',
              href:'/reports/vacation_report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'جهاز البصمة',
              enname: 'Supervisor Report',
              href:'',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            
            
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'تقرير الدليل',
              enname: 'COA Report',
              href:'/coa-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الدليل بالمجاميع',
              enname: 'COA Totals Report',
              href:'/coa-reports-totals',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
       
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      demosamerp:{
        website: {
          main: {
            index: 98,
            arname: 'المتجر',
            enname: 'Web & Store',
            urduname: 'فروخت',
            href: '/web-store',
            to: '/web-store',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            // {
            //   index:8,
            //   main: 98,
            //   arname: 'الموقع الالكتروني',
            //   enname: 'website',
            //   href:'/website',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,6,7,8],
            //   perms: ['any']
            // },
            {
              index:7,
              main: 98,
              arname: 'المتجر',
              enname: 'web-store',
              href:'/web-store',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            
          ]
        }, 
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            href: '/dashboard',
            to: '/dashboard',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              href:'/dashboard',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:7,
              main: 88,
              arname: 'اعدادات العروض',
              enname: 'Offers',
              href:'/Offers',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['showOffering']
            },
            {
              index:9,
              main: 88,
              arname: 'مركز الاتصال',
              enname: 'Call Center',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:9,
              main: 88,
              arname: 'التواصل الاجتماعي',
              enname: 'Social Media',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['showCallCenter']
            },
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            // {
            //   index:10,
            //   main: 88,
            //   arname: 'تقرير الضمان',
            //   enname: 'Recuring Maint',
            //   href:'/periodic-maint',
            //   // href:'',
            //   style: '',
            //   class:'',
            //   smclass: '', //smclass: 'font-small',
            //   groupid:[1,2,3,4,5,7,8],
            //   perms: ['any']
            // },
            {
              index:10,
              main: 88,
              arname: 'الصيانة الدورية',
              enname: 'Recuring Maint',
              href:'/periodic-maint',
              // href:'',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showPeriodMaint']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:7,
              main: 88,
              arname: 'العروض التسويقية',
              enname: 'Subscriber',
              href:'/subscriber',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['showSubscriber']
            },
            
            
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود التأمين',
              enname: 'Insurance. Contract',
              href:'/insurance-contracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
           
            
            {
              index:7,
              main: 88,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['workreport']
            },
            
            
          ]
        },
        workJob: {
          main: {
            index: 99,
            arname: 'الصيانة',
            enname: 'Works',
            href: '/sam-home',
            to: '/sam-home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:10,
              main: 99,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/sam-home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:2,
              main: 99,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 99,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'نقاط البيع',
              enname: 'POS',
              href:'/POS',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:1,
              main: 2,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
          ]
        },
        
        accounting: {
          main: {
            index: 3,
            arname: 'المحاسبة',
            enname: 'Accounting',
            href: '/finance/coa',
            to: '/finance/coa',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 3,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 3,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 3,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 3,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 3,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 3,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 3,
              arname: 'انواع المصروفات',
              enname: 'Expenses Types',
              href:'/settings/expensesTypes',
              class:'mytab4',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['onlySalesPurchase']
            },
            {
              index:5,
              main:3,
              arname: 'المصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 3,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 3,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            href: '/stores',
            to: '/stores',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'المستودعات',
              style: '',
              enname: 'Stores',
              href:'/stores',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'الوحدات',
              style: '',
              enname: 'Units',
              href:'/inventory/units',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تحويل مخزون',
              style: '',
              enname: 'Transfer Inevent',
              href:'/stores-transfer',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: ' الأرصدة الافتتاحية',
              style: '',
              enname: 'Start Inventory',
              href:'/start-Inventory',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main: 4,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:5,
              main: 4,
              arname: 'الاقل مبيعا',
              enname: 'Less Sales',
              href:'/report/items-less',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
          ]
        },
        purchase: {
          main: {
            index: 12,
            arname: 'المشتريات',
            enname: 'Inventory',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 12,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'أمر شراء',
              style: '',
              enname: 'Purchase',
              href:'/purchase-order',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'المشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 12,
              arname: 'مرتجع المشتريات',
              style: '',
              enname: 'purchase Return',
              href:'/purchaseReturn',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },

            {
              index:4,
              main: 12,
              arname: 'تقرير المشتريات',
              style: '',
              enname: 'Purchase Report',
              href:'/purchase-report',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير النسب',
              enname: 'Percentage Report',
              href:'/reports/percent-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الاجازات',
              enname: 'Vacation Report',
              href:'/reports/vacation_report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'جهاز البصمة',
              enname: 'Supervisor Report',
              href:'',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            
            
            {
              index:3,
              main: 6,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'تقرير الدليل',
              enname: 'COA Report',
              href:'/coa-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 6,
              arname: 'الدليل بالمجاميع',
              enname: 'COA Totals Report',
              href:'/coa-reports-totals',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
       
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      demosmall:{ 
        website: {
          main: {
            index: 98,
            arname: 'الموقع والمتجر',
            enname: 'Web & Store',
            urduname: 'فروخت',
            href: '/website',
            to: '/website',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 98,
              arname: 'الموقع الالكتروني',
              enname: 'website',
              href:'/website',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 98,
              arname: 'المتجر',
              enname: 'webstore',
              href:'/webstore',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            
          ]
        },
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            urduname: 'انتظام',
            href: '/ecar',
            to: '/ecar',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              urduname: '',
              href:'/ecar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              urduname: '',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            urduname: 'فروخت',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:6,
              main: 88,
              arname: 'الفحص',
              enname: 'Check Car',
              href:'',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
          ]
        },
        workJob: {
          main: {
            index: 99,
            arname: 'الصيانة',
            enname: 'Works',
            urduname: 'دیکھ بھال',
            href: '/home',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:10,
              main: 99,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:2,
              main: 99,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 99,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2,3,4,5,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الارباح',
              enname: 'card Profits',
              href:'/card-profit-reports',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            urduname: 'رسیدیں',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'نقاط البيع',
              enname: 'POS',
              href:'/POS',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            urduname: 'انوینٹری',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['anyxxx']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            urduname: 'ملازمین',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carsddd']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            urduname: 'رپورٹس',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carddds']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 6,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 6,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            urduname: 'ترتیبات',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            urduname: 'تربیت',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            urduname: 'لاگ آوٹ',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      small:{ 
       
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            urduname: 'انتظام',
            href: '/ecar',
            to: '/ecar',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              urduname: '',
              href:'/ecar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              urduname: '',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            urduname: 'فروخت',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:6,
              main: 88,
              arname: 'الفحص',
              enname: 'Check Car',
              href:'',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
          ]
        },
        workJob: {
          main: {
            index: 99,
            arname: 'الصيانة',
            enname: 'Works',
            urduname: 'دیکھ بھال',
            href: '/home',
            to: '/home',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:10,
              main: 99,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:2,
              main: 99,
              arname: 'أوامر العمل',
              enname: 'Work Orders',
              href:'/emps/workorders',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الاعمال',
              enname: 'Work report',
              href:'/reports/workType',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:5,
              main: 99,
              arname: 'تقرير المركبة',
              enname: 'Car Report',
              href:'/car-report',
              class:'mytab4',
              groupid:[1,2,3,4,5,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['cars']
            },
            {
              index:7,
              main: 99,
              arname: 'تقرير الارباح',
              enname: 'card Profits',
              href:'/card-profit-reports',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            urduname: 'رسیدیں',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'نقاط البيع',
              enname: 'POS',
              href:'/POS',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            urduname: 'انوینٹری',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['anyxxx']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            urduname: 'ملازمین',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carsddd']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            urduname: 'رپورٹس',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carddds']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 6,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 6,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            urduname: 'ترتیبات',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            urduname: 'تربیت',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            urduname: 'لاگ آوٹ',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      whatsapp:{ 
        settings: {
          main: {
            index: 1,
            arname: 'الاعدادات',
            enname: 'Settings',
            urduname: 'ترتیبات',
            href: '/settings-whatsapp',
            to: '/settings-whatsapp',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            
          ]
        },
                
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            urduname: 'لاگ آوٹ',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      sfatora:{ 
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            urduname: 'فروخت',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            }
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            urduname: 'رسیدیں',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:10,
              main: 2,
              arname: 'أمر تشغيل',
              enname: 'Job Order',
              href:'/home',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            }
          ]
        },

        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            urduname: 'رپورٹس',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            urduname: 'ترتیبات',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            urduname: 'تربیت',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            urduname: 'لاگ آوٹ',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
      full: {
        sales: {
          main: {
            index: 1,
            arname: 'المبيعات',
            enname: 'Sales',
            href: '/home',
            to: '/invoicelist',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 1,
              arname: 'بطاقات الصيانة',
              enname: 'Maint. Cards',
              href:'/home',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 1,
              arname: 'الفواتير',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 1,
              arname: 'العملاء',
              enname: 'Customers',
              href:'/clientList',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            
            {
              index:4,
              main: 1,
              arname: 'مراجعة العملاء',
              enname: 'Client Review',
              href:'/billClients',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 1,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 1,
              arname: 'الدعم الفني',
              enname: 'Support',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 1,
              arname: 'التجديد',
              enname: 'Renew',
              href:'',
              class:'mytab4',
              groupid:[1,2,3,4,5,6,7,8],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        Accountants: {
          main: {
            index: 2,
            arname: 'المحاسبة',
            enname: 'Accountants',
            href: '/finance/coa',
            to: '/finance/coa',
            class: 'd',
            // smclass: '', //smclass: 'font-small',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'شجرة الحسابات',
              enname: 'Chart Of Account',
              href:'/finance/coa',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'القيود',
              enname: 'JVs',
              href:'/finance/jvs',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'أرصدة افتتاحية',
              enname: 'Opening Balance',
              href:'/finance/fient',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 2,
              arname: 'طرق الدفع',
              enname: 'Payment Types',
              href:'/finance/paytypes',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 2,
              arname: 'الأصول الثابتة',
              enname: 'Fixed Assets',
              href:'/finance/fixed-assets',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 2,
              arname: 'ربط الدليل',
              enname: 'Integrations',
              href:'/finance/definition',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 2,
              arname: 'ملخص المصاريف',
              enname: 'Expenses Summary',
              href:'/expenses/summary',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 2,
              arname: 'مصروفات عامة',
              enname: 'General Expenses',
              href:'/expenses/general',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 2,
              arname: 'مصروفات ادارية',
              enname: 'Manage. Expenses',
              href:'/expenses/management',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:11,
              main: 2,
              arname: 'مصروفات دورية',
              enname: 'Recuring Expenses',
              href:'/expenses/recurring',
              class:'mytab4',
              groupid:[1,2,],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
          ]
        },
        store: {
          main: {
            index: 3,
            arname: 'المخزون',
            enname: 'Store',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: 'd',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 2,
              arname: 'الموردين',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 2,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/inventory/purchase',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'أقسام المخزون',
              enname: 'Prod. Cats',
              href:'/inventory/products-cats',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 2,
              arname: 'المنتجات',
              enname: 'Products',
              href:'/inventory/products',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 2,
              arname: 'الأكثر مبيعا',
              enname: 'Most Sales',
              href:'/inventory/best-seller',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 2,
              arname: 'الأقل مبيعا',
              enname: 'Less Sales',
              href:'/inventory/lowest-selling',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 2,
              arname: 'المنتهية',
              enname: 'Finished',
              href:'/inventory/finished',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 2,
              arname: 'حرجة صنف',
              enname: 'Items Records',
              href:'/inventory/itemtrans',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        employees: {
          main: {
            index: 4,
            arname: 'الموظفين',
            enname: 'HR',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 4,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 4,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 4,
              arname: 'تقرير التأمينات',
              enname: 'GOZI Report',
              href:'/emps/gozi-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
          ]
        },
        reports: {
          main: {
            index: 5,
            arname: 'التقارير',
            enname: 'Reports',
            href: '/reports/vat-reports',
            to: '/reports/vat-reports',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'تقرير الدخل',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:3,
              main: 5,
              arname: 'كشف حساب',
              enname: 'Statements',
              href:'/reports/statment-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:4,
              main: 5,
              arname: 'ميزان المراجعة',
              enname: 'Trial Balance',
              href:'/reports/balance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'الارباح والخسائر',
              enname: 'PNL Report',
              href:'/reports/bnl-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:6,
              main: 5,
              arname: 'الميزانية',
              enname: 'Balance Report',
              href:'/reports/acbalance-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 5,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 5,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 5,
              arname: 'سندات القبض',
              enname: 'Income Voucher',
              href:'/finance/inreceipt',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:10,
              main: 5,
              arname: 'سندات الصرف',
              enname: 'Out Voucher',
              href:'/finance/outreceipt',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            
          ]
        },
        settings: {
          main: {
            index: 6,
            arname: 'الاعدادات',
            enname: 'Settings',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 6,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              groupid:[1],
              perms: ['any']
            },
            {
              index:4,
              main: 6,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/settings/employees',
              class:'mytab4',
              groupid:[1,2],
              style: '',
              perms: ['onlySalesPurchase']
            },
            // {
            //   index:5,
            //   main: 6,
            //   arname: 'انواع المصروفات',
            //   enname: 'Expenses Types',
            //   href:'/settings/expensesTypes',
            //   class:'mytab4',
            //   style: '',
            //   groupid:[1,2],
            //   perms: ['onlySalesPurchase']
            // },
            {
              index:6,
              main: 6,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/employees',
              class:'mytab4',
              groupid:[1,2],
              style: '',
              perms: ['onlySalesPurchase']
            },
          ]
        }
      },
      fatoora:{ 
        dashboard: {
          main: {
            index: 1,
            arname: 'الادارة',
            enname: 'Management',
            urduname: 'انتظام',
            href: '/ecar',
            to: '/ecar',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 1,
              arname: 'تقارير بيانية',
              enname: 'Management',
              urduname: '',
              href:'/ecar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التعميدات',
              enname: 'Management',
              urduname: '',
              href:'/dashboard33',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'الاصول الثابتة',
              enname: 'Fixed Assets',
              href:'/management/fixed-assets',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير اليومية',
              enname: 'Daily Report',
              href:'/reports/income-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'المشتريات',
              enname: 'Purchase',
              href:'/mg-purchase-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'تقرير الاداء',
              enname: 'KPI Report',
              href:'/reports/kpi-report',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'عروض تسويقية',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'العقود',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:8,
              main: 1,
              arname: 'التحصيل',
              enname: 'Settings',
              href:'/dashboard11',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            
            
          ]
        },
        customers: {
          main: {
            index: 88,
            arname: 'المبيعات',
            enname: 'Customers',
            urduname: 'فروخت',
            href: '/clientList',
            to: '/clientList',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,7,8,9],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:8,
              main: 88,
              arname: 'العملاء',
              enname: 'Clients',
              href:'/clientList',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['any']
            },
            {
              index:7,
              main: 88,
              arname: 'عروض السعر',
              enname: 'Quotations',
              href:'/quotations/n/1',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            {
              index:10,
              main: 88,
              arname: 'المواعيد',
              enname: 'Booking',
              href:'/calendar',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showBooking']
            },
            {
              index:10,
              main: 88,
              arname: 'عقود صيانة',
              enname: 'Maint. Contract',
              href:'/maintContracts',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showMintContract']
            },
            {
              index:6,
              main: 88,
              arname: 'الفحص',
              enname: 'Check Car',
              href:'',
              class:'',
              // style: 'background:red !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,6,7,8],
              perms: ['show_jobcard']
            },
          ]
        },
        sales: {
          main: {
            index: 2,
            arname: 'الفواتير',
            enname: 'Sales',
            urduname: 'رسیدیں',
            href: '/invoicelist',
            to: '/invoicelist',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 2,
              arname: 'فاتورة مبيعات',
              enname: 'Invoices',
              href:'/invoicelist',
              class:'',
              // style: 'background:green !important;color:#fff !important;',
              style: '',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['showInvoiceList']
            },
            {
              index:4,
              main: 2,
              arname: 'فاتورة مشتريات',
              style: '',
              enname: 'Purchase',
              href:'/purchase',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:5,
              main:2,
              arname: 'فاتورة مصروفات',
              enname: 'Expenses',
              href:'/general-expenses',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:3,
              main: 2,
              arname: 'التحصيل',
              enname: 'Renewal',
              href:'/invoices-not-paid',
              style: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2,3,4,5,7,8],
              perms: ['any']
            },
            
          ]
        },
        store: {
          main: {
            index: 4,
            arname: 'المخزون',
            enname: 'Inventory',
            urduname: 'انوینٹری',
            href: '/inventory/vendors',
            to: '/inventory/vendors',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:4,
              main: 4,
              arname: 'الموردين',
              style: '',
              enname: 'Vendors',
              href:'/inventory/vendors',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:8,
              main: 4,
              arname: 'اقسام المخزون',
              enname: 'Inentory Cats',
              style: '/inventory/products-cats',
              href:'/inventory/products-cats',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:9,
              main: 4,
              arname: 'أصناف المشتريات',
              enname: 'Inentory Items',
              style: '',
              href:'/inventory/products',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['any']
            },
            {
              index:4,
              main: 4,
              arname: 'تقرير الجرد',
              style: '',
              enname: 'Inventory Report',
              href:'/inventory-reports',
              // herf: '',
              class:'',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              perms: ['anyxxx']
            },
          ]
        },
        employees: {
          main: {
            index: 5,
            arname: 'الموظفين',
            enname: 'HR',
            urduname: 'ملازمین',
            href: '/emps/salaries',
            to: '/emps/salaries',
            class: '',
            // smclass: '', //smclass: 'font-small',
            smclass: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff'
          },
          sub: [
            {
              index:1,
              main: 5,
              arname: 'الموظفين',
              enname: 'Employees',
              href:'/emps/salaries',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main:5,
              arname: 'مسير الرواتب',
              enname: 'Salary Table',
              href:'/emps/salary-table',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'العهد',
              enname: 'Custody',
              href:'/emps/custody',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 5,
              arname: 'الحضور والانصراف',
              enname: 'Attendess',
              href:'/emps/attendees',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: `'font-small',
              smclass: '',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 5,
              arname: 'تقرير المشرف',
              enname: 'Supervisor Report',
              href:'/supervisor-report',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carsddd']
            },
            
          ]
        },
        reports: {
          main: {
            index: 6,
            arname: 'التقارير',
            enname: 'Reports',
            urduname: 'رپورٹس',
            href: '/reports/income-report',
            to: '/reports/income-report',
            style: '',
            class: '',
            groupid: [1,2],
            bgcolor: '#000000',
            color: '#ffffff',
          },
          sub: [
            {
              index:1,
              main: 6,
              arname: 'تقرير اليومية',
              enname: 'Income Report',
              href:'/reports/income-report',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:2,
              main: 6,
              arname: 'تقرير الضريبة',
              enname: 'VAT Report',
              href:'/reports/vat-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:5,
              main: 6,
              arname: 'الاكثر مبيعا',
              enname: 'Most Sales',
              href:'/report/items-max',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['carddds']
            },
            {
              index:7,
              main: 6,
              arname: 'تقرير المبيعات',
              enname: 'Sales Report',
              href:'/reports/sales-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:8,
              main: 6,
              arname: 'تقرير المرتجع',
              enname: 'Return Report',
              href:'/reports/return-reports',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any1']
            },
            {
              index:5,
              main: 6,
              arname: 'تقرير المصروفات',
              enname: 'Expenses Type',
              href:'/reports/expenses-types',
              class:'mytab4',
              groupid:[1,2],
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              style: '',
              perms: ['any']
            },
            {
              index:9,
              main: 6,
              arname: 'طباعة سند صرف',
              enname: 'Exch. Voucher',
              href:'/finance/outreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
            {
              index:8,
              main: 6,
              arname: 'طباعة سند قبض',
              enname: 'ٌReceipt Voucher',
              href:'/finance/inreceipt',
              class:'',
              // smclass: '', //smclass: 'font-small',
              smclass: '', //smclass: 'font-small',
              groupid:[1,2],
              style: '',
              perms: ['any']
            },
          ]
        },
        settings: {
          main: {
            index: 7,
            arname: 'الاعدادات',
            enname: 'Settings',
            urduname: 'ترتیبات',
            href: '/settings/system-settings',
            to: '/settings/system-settings',
            class: '',
            groupid: [1],
            bgcolor: '#000000',
            color: '#ffffff',
            perms: ['any']
          },
          sub: [
            {
              index:1,
              main: 7,
              arname: 'اعدادات النظام',
              enname: 'System settings',
              href:'/settings/system-settings',
              style: '',
              class:'mytab4',
              groupid:[1,2],
              smclass: '', //smclass: 'font-small',
              perms: ['any']
            },
            {
              index:2,
              main: 7,
              arname: 'المستخدمين',
              enname: 'Users settings',
              href:'/settings/users-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الصلاحيات',
              enname: 'Permissions',
              href:'/settings/permissions-settings',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            {
              index:3,
              main: 7,
              arname: 'الربط API',
              enname: 'WhatsApp Settings',
              href:'/settings-whatsapp',
              style: '',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              perms: ['any']
            },
            
            {
              index:6,
              main: 7,
              arname: 'اعدادات الحالات',
              enname: 'Stauts Settings',
              href:'/settings/statuses',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
            {
              index:7,
              main: 7,
              arname: 'اعدادات الطباعة',
              enname: 'Printing Settings',
              href:'/settings/printing',
              class:'mytab4',
              smclass: '', //smclass: 'font-small',
              groupid:[1],
              style: '',
              perms: ['any']
            },
          ]
        },
        training: {
          main: {
            index: 100,
            arname: 'التدريب',
            enname: 'training',
            urduname: 'تربیت',
            href: '',
            to: '',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#000000',
            color: '#ffffff',
            frame: 'iframe',
            border: '#000',
            perms: ['any']
          },
          sub: []
        },
        logout: {
          main: {
            index: 101,
            arname: 'تسجيل خروج',
            enname: 'Logout',
            urduname: 'لاگ آوٹ',
            href: '/logout',
            to: '/logout',
            class: '',
            smclass: '', //smclass: 'font-small',
            groupid: [1,2,3,4,5,6,7,8],
            bgcolor: '#FF0000',
            color: '#ffffff',
            frame: 'logout',
            border: '#FF0000',
            perms: ['any'] 
          },
          sub: []
        }
      },
    },
    caneditinvoicedate: true,
    settings: [],
    connected: false,
    products: [],
    smsTemp:[],
    hometitle: 'الفواتير',
    hometitle_en: 'Invoices',
    carsys: false,
    debug:0,
    licenseType: {
      manafactoring_enable: false,
      show_jobcard: false,
      sam_project: false,
      car_contract: false,
      samcrm: false,
      showIDNumber: false,
      erp: false,
      showOwnerName: false,
      showCarPlate: false,
      perDiscount: false,
      showPlate: false,
      requirePurchase: false,
      showSketch: false,
      showCardNums: false,
      startYear: 2021,
      activemenu: '',
      otherproducts: false,
      cars: false,
      carInfoRequired: false,
      parts: false,
      bill: false,
      quotation: false,
      clientStatment:false,
      carinfo: false,
      salarysys: false,
      showproject: false,
      crm: false,
      showAddedBy: false,
      printDelNotes: false,
      agentPurchase: false,
      expenses: false,
      POSREST: false,
      POSRET: false,
      families: false,
      fullversion:false,
      orderlic: false,
      onlySalesPurchase: true,
      isBill: false,
      isSupport: false,
      isCar: false,
      showInvoiceList: false,
      showMaintCart:false,
      showItemCode: false,
      require_itemcode: false,
      moneyComma: false,
      showExtra: false,
      demoVersion: false,
    },
    viewhomeaddinvo:false,
    zeroPrice:true,
    SystemName: 'SAMCOTEC',
    Version: "ERP - 3.3.01", 
    // Version: "ECAR - 3.2.10", 
    deflang: 'ar',
    
    group_id: '',

    headerIcone: 1
  },
  getters:{
    state: (state) => state
  },
  mutations: {
    setLicenseType: function(state,val) {
      Object.keys(val).forEach(key => {
        if(key != 'activemenu'  && key != 'logo'){
          state.licenseType[key] = true;
        }
        else state.licenseType[key] = val[key];
      });
    },
  },
  actions: {

  },
  modules: {
    billStore,
    inOutReport,
    customerSupport
  }
})
